<template>
  <view-item title="配件入库">
    <template #operation>
      <en-button type="primary" @click="operation.add.click">创建单据</en-button>
      <button-ajax v-if="form.data.id" :disabled="form.disabled" type="primary" :method="operation.save.click">保存</button-ajax>
      <button-ajax v-if="form.data.id" :disabled="form.disabled" type="primary" :method="operation.commit.click">保存并入库</button-ajax>
      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button>操作</en-button>
        <template #dropdown>
          <en-dropdown-item :disabled="form.disabled" command="detail"> 移库入库单 </en-dropdown-item>
          <en-dropdown-item  command="logs">单据历史</en-dropdown-item>
          <en-dropdown-item :disabled="form.disabled" command="delete">删除</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        code="STKISPFLD"
        :ajax="{ action: 'GET /enocloud/stock/transfer/in/query' }"
        :props="{ start: 'startDate', end: 'endDate' }"
        :routes="[
          { path: '/accessory/stock/in/purchase', name: '采购单' },
          { path: '/accessory/stock/in/refund', name: '采退单' },
          { path: '/accessory/stock/in/other', name: '其他进货单' },
          { path: '/accessory/stock/in/transfer', name: '移库入库单' }
        ]"
        @row-click="manifest.row.click"
        @expand-click="dialog.visible = true"
        :ref="setRef('manifest')"
      >
        <template #STATUS="{ row }: { row: EnocloudInventoryDefinitions['StockTransferInQueryDto'] }">
          <en-tag :type="row.status?.code === 'S' ? 'success' : row.status?.code === 'P' ? 'warning' : 'danger'">{{ row.status?.message }}</en-tag>
        </template>

        <template #form="{ data }">
          <en-form-item label="调出仓库">
            <select-maintain
              v-model="data.fromWarehouseId"
              :ajax="{
                action: 'GET /enocloud/common/warehouse',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
              :disabled="form.disabled"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="调入仓库">
            <select-maintain
              v-model="data.toWarehouseId"
              :ajax="{
                action: 'GET /enocloud/common/warehouse',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
              :disabled="form.disabled"
              class="w-full"
            ></select-maintain>
          </en-form-item>

          <en-form-item label="制单人">
            <select-maintain
              v-model="data.preparedById"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
              multiple
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="经手人">
            <select-maintain
              v-model="data.auditById"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
              multiple
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="备注">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <div class="flex gap-4">
          <span>业务选择:移库入库</span>
        </div>

        <div class="flex items-center gap-6">
          <span>出货仓库：{{ form.data.stockTransferOut?.fromWarehouse?.name }}</span>
          <span>入货仓库：{{ form.data.stockTransferOut?.toWarehouse?.name }}</span>
          <span>{{ form.data.preparedBy?.name }}</span>
          <span>{{ form.data.auditBy?.name }}</span>
          <en-tag :type="form.data.status?.code === 'S' ? 'success' : form.data.status?.code === 'P' ? 'warning' : 'danger'">{{
                  form.data.status?.message
                }}</en-tag>
          <span>{{ form.data.serialNo }}</span>
          <span>{{ formatDate(form.data.preparedDatetime) }}</span>
          <div v-if="form.data.stockTransferOut?.imgUrls.length" class="flex items-center">
            <span> 物流单：</span>
            <en-button link type="primary" @click="form.logisiticsImageUrls.click">查看</en-button>
          </div>
          <div class="flex items-center">
            <span> 备注：</span>
            <en-icon color="#4C58D9">
              <icon-edit></icon-edit>
            </en-icon>
          </div>
        </div>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane
                label="配件"
                name="goods"
                :badge="form.data.stockTransferInGoods?.length"
                :style="{ height: height - 55 + 'px', overflow: 'auto' }"
                class="gap-6"
              >
                <div class="flex gap-4">
                  <en-button type="primary" :disabled="form.disabled" @click="form.stockTransferInGoods.batch.click">选择单据配件</en-button>
                </div>

                <flex-box>
                  <template #default="{ height }">
                    <table-dynamic code="STFIDLFD" :data="form.data.stockTransferInGoods" :height="height">
                      <template #OPERATION="{ row, $index }: { row: EnocloudInventoryDefinitions['StockTransferInGoodsDto']; $index: number }">
                        <en-button type="primary" link :disabled="form.disabled" @click="form.stockTransferInGoods.delete.click($index)">
                          删除
                        </en-button>
                      </template>

                      <template #NO="{ $index }: { $index: number }">{{ $index + 1 }}</template>

                      <template #OEM="{ row }: { row: EnocloudInventoryDefinitions['StockTransferInGoodsDto'] }">{{ row.goods?.oem }}</template>

                      <template #NAME="{ row }: { row: EnocloudInventoryDefinitions['StockTransferInGoodsDto'] }">{{ row.goods?.name }}</template>

                      <template #UNIT="{ row }: { row: EnocloudInventoryDefinitions['StockTransferInGoodsDto'] }">
                        {{ row.goodsSpecification?.name }}
                      </template>

                      <template #SPECIFICATION="{ row }: { row: EnocloudInventoryDefinitions['StockTransferInGoodsDto'] }">
                        {{ `${row.goodsSpecification?.weight}${row.goods?.warehouseUnit}` }}
                      </template>

                      <template #BRAND="{ row }: { row: EnocloudInventoryDefinitions['StockTransferInGoodsDto'] }">
                        {{ row.goods?.brand }}
                      </template>

                      <template #PRODUCTION_PLACE="{ row }: { row: EnocloudInventoryDefinitions['StockTransferInGoodsDto'] }">
                        {{ row.goods?.placeOfProduction }}
                      </template>

                      <template #BARCODE="{ row }: { row: EnocloudInventoryDefinitions['StockTransferInGoodsDto'] }">
                        {{ row.goods?.barcode }}
                      </template>

                      <template #COUNT="{ row }: { row: EnocloudInventoryDefinitions['StockTransferInGoodsDto'] }">
                        <en-input v-model="row.count" :disabled="form.disabled"></en-input>
                      </template>

                      <template #MODEL="{ row }: { row: EnocloudInventoryDefinitions['StockTransferInGoodsDto'] }">
                        {{ row.goods?.model }}
                      </template>

                      <template #COMMENT="{ row }: { row: EnocloudInventoryDefinitions['StockTransferInGoodsDto'] }">
                        <en-input v-model="row.comment" :disabled="form.disabled"></en-input>
                      </template>
                    </table-dynamic>
                  </template>
                </flex-box>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <stock-in-transfer-detail v-model="detail.visible" :data="form.data" @confirm="form.get"></stock-in-transfer-detail>

  <stock-in-transfer-dialog v-model="dialog.visible"></stock-in-transfer-dialog>

  <stock-in-transfer-logs v-model="logs.visible" :data="form.data"></stock-in-transfer-logs>

  <stock-out-transfer-selection v-model="selection.visible" @selection-change="selection.change"></stock-out-transfer-selection>
</template>

<script lang="ts">
import { h } from 'vue'
import { Edit as IconEdit } from '@element-plus/icons-vue'
import { EnMessageBox } from '@enocloud/components'
import { calculator } from '@enocloud/utils'

import StockInTransferDialog from '@accessory/components/stock-in-transfer-dialog.vue'
import StockInTransferDetail from '@accessory/components/stock-in-transfer-detail.vue'
import StockInTransferLogs from '@accessory/components/stock-in-transfer-logs.vue'
import StockOutTransferSelection from '@accessory/components/stock-out-transfer-selection.vue'

export default factory({
  components: {
    StockInTransferDialog,
    StockInTransferDetail,
    StockOutTransferSelection,
    StockInTransferLogs,
    IconEdit
  },

  config: {
    children: {
      operation: {
        add: {
          click() {
            this.form.init()
            this.selection.visible = true
          }
        },
        save: {
          async click() {
            await this.form.update()
            return this.form.get()
          }
        },
        commit: {
          async click() {
            await this.form.update()
            await this.form.audit({ addition: { id: this.form.data.id, additionalOperationCode: 'ADSI' } })
            return this.form.get()
          }
        },
        option: {
        async  command(option: string) {
            switch (option) {
              case 'detail':
                this.detail.visible = true
                break
              case 'logs':
                this.logs.visible = true
                break
              case 'delete':
              await EnMessageBox.confirm('是否确认删除单据', '提示')
                  await this.form.delete()
                  this.form.init()
                  this.refs.manifest.table.method()
            }
          }
        }
      },
      manifest: {
        computed: {
          code() {
            switch (this.code) {
              case 'PUC':
                return 'PUCIFD'
              case 'PUCR':
                return 'PUCRFD'
              case 'OTH':
                return 'PUCOFD'
              case 'TRA':
                return 'STFIFD'
            }
          }
        },
        children: {
          form: {
            data: {
              typeCode: '',
              businessStartDate: '',
              businessEndDate: '',
              quickSearch: '',
              goodsOem: '',
              goodsSerialNo: '',
              goodsName: '',
              invoiceTypeName: '',
              settlementProgressCode: '',
              startDate: '',
              endDate: '',
              goodsVehicleSpec: '',
              fromWarehouseId: '',
              toWarehouseId: '',
              preparedById: '',
              auditById: '',
              comment: ''
            }
          },
          row: {
            click(row: EnocloudInventoryDefinitions['StockInDto']) {
              this.form.init()
              this.form.data.id = row.id
              this.form.get()
            }
          }
        }
      },
      tabs: {
        active: 'goods'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/stock/transfer/in/:stockTransferInId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/stock/transfer/in',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/stock/transfer/in',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          delete: {
            action: 'DELETE /enocloud/stock/transfer/in/:stockTransferInId',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          audit: {
            action: 'POST /enocloud/stock/transfer/in/:stockTransferInId/audit',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A'
          },
          summary() {
            return this.form.data.stockTransferInGoods?.reduce(
              (res, item) => {
                res.count = calculator.add(res.count, item.count)
                res.amount = calculator.add(res.amount, calculator.mul(item.count, item.amount))
                return res
              },
              {
                count: 0,
                amount: 0
              }
            )
          }
        },
        children: {
          imgUrls: {
            click() {
              EnMessageBox({
                title: '物流单照片',
                message: h(
                  'div',
                  { class: 'flex gap-4 flex-wrap' },
                  this.form.data.stockTransferOut?.imgUrls.map((url) => {
                    return h('img', { src: url, width: 300, height: 300 })
                  })
                )
              })
            }
          },
          logisiticsImageUrls: {
            click() {
              EnMessageBox({
                title: '物流单照片',
                message: h(
                  'div',
                  { class: 'flex gap-4 flex-wrap' },
                  this.form.data.stockTransferOut?.imgUrls.map((url) => {
                    return h('img', { src: url, width: 300, height: 300 })
                  })
                )
              })
            }
          },
          stockTransferInGoods: {
            add: {
              change(value: EnocloudCommonDefinitions['GoodsDto']) {
                this.form.data.stockTransferInGoods?.push({
                  goods: value,
                  goodsSpecification: value.specifications?.find((item) => item.defaultPurchase?.value) || value.specifications?.[0]
                })
              }
            },
            batch: {
              click() {
                this.selection.visible = true
              }
            },
            delete: {
              click(index: number) {
                this.form.data.stockTransferInGoods?.splice(index, 1)
              }
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      detail: {
        visible: false
      },
      selection: {
        visible: false,
        async change(data: EnocloudInventoryDefinitions['StockTransferOutDto'], items: EnocloudInventoryDefinitions['StockTransferOutGoodsDto'][]) {
          this.form.data.stockTransferOut = data
          this.form.data.stockTransferInGoods = items
            .filter((item) => item.count && item.count > 0)
            .map((item) => {
              return {
                goods: item.goods,
                goodsSpecification: item.goodsSpecification,
                count: calculator.sub(item.count, item.transferredIn?.count ?? 0),
                stockTransferOutGoods: item
              }
            })

          const res = await this.form[this.form.data.id ? 'update' : 'submit']()
          this.form.data.id ??= res.data[0] as number | undefined
          this.form.get()
        }
      },
      logs: {
        visible: false
      }
    }
  }
})
</script>
